import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { FORM_ERROR } from 'final-form'
import { submitBookingInquiry } from '../../../../firebase/functions'
import { Form } from '../../../forms/Form'
import { toAmPm } from '../../../forms/input/time'
import { bookingFields } from '../../fields'

export const Booking = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const toast = useToast()

  return (
    <>
      <Button onClick={() => onOpen()}>BOOKING</Button>
      <Modal
        scrollBehavior='inside'
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent height='500px' shadow='none' bg='transparent' p={2}>
          <ModalBody
            style={{ overflowX: 'hidden', overflowY: 'auto' }}
            px={2}
            borderRadius={4}
            bg='whiteAlpha.900'
          >
            <VStack position='relative' spacing={0} w='100%'>
              <ModalCloseButton
                position='absolute'
                color='#E46E00'
                top='0'
                left='0'
              />
              <Heading
                py={4}
                position='relative'
                fontSize='1.6rem'
                color='#E46E00'
              >
                BOOKING REQUEST
              </Heading>
              <Form
                field={bookingFields}
                onSubmit={({ timeEnd, timeStart, ...data }) => {
                  return submitBookingInquiry({
                    timeEnd: toAmPm(timeEnd),
                    timeStart: toAmPm(timeStart),
                    ...data,
                  })
                    .then(() => {
                      toast({
                        status: 'success',
                        description:
                          "Your catering request has been sent, we'll get back to you within 1-3 business days!",
                      })
                      onClose()
                      return undefined
                    })
                    .catch((err) => {
                      console.error(err)
                      toast({
                        status: 'error',
                        description:
                          'Something went wrong - get in touch on instagram for now!',
                      })
                      return { [FORM_ERROR]: 'Something happened' }
                    })
                }}
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
