import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
  VStack,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react'
import useResizeObserver from '@react-hook/resize-observer'
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import YouTube from 'react-youtube'
import { AppContext } from '../../../../context'
import { useSize } from '../../../../hooks/useSize'
import { aboutBlurbs } from './aboutBlurbs'

const headingColors = ['#1C75BC', '#E46E00', '#1C75BC']
export const About = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { screen } = useContext(AppContext)
  const { width: screenWidth = 0 } = screen || {}
  const width = useMemo(() => Math.min(screenWidth * 0.95, 400), [screenWidth])

  return (
    <>
      <Button onClick={() => onOpen()}>ABOUT</Button>
      <Modal
        scrollBehavior='inside'
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent height='500px' shadow='none' bg='transparent' p={2}>
          <ModalBody width={width} p={0} borderRadius={4} bg='whiteAlpha.900'>
            <VStack spacing={0} w='100%'>
              <ModalCloseButton left='0.7rem' top='0.7rem' zIndex={2} color='#E46E00' />
              {aboutBlurbs.map(({ heading, body, videoId }, i) => (
                <VStack w='100%' position='relative' key={heading} spacing={0}>
                  <Heading
                    boxShadow='0 4px 4px rgba(0,0,0,0.5)'
                    py={2}
                    background='white'
                    // textShadow='0 0 4px black'
                    px={8}
                    w='100%'
                    fontFamily='Lemonada'
                    textAlign='center'
                    fontSize='1.1rem'
                    color={headingColors[1]}
                    position='sticky'
                    top='0'
                  >
                    {heading}
                  </Heading>
                  <Text p={6} fontSize='1.1rem' textAlign='left'>
                    {body}
                  </Text>
                  {videoId ? (
                    <YouTube
                      opts={{ width, height: width / 1.6 }}
                      videoId={videoId}
                    />
                  ) : null}
                </VStack>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
