import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from './components/App'
const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
const client = new QueryClient()
root.render(
  <QueryClientProvider client={client}>
    <App />
  </QueryClientProvider>
)
