import { ChevronDownIcon } from "@chakra-ui/icons";
import { VStack, Text, Menu, MenuButton, Button, MenuList, MenuItem, useDisclosure, Flex } from "@chakra-ui/react";
import { SelectField } from "@peaceful-creations/shared";
import { FC, useMemo } from "react";
import { InputProps } from "../types";


export const SelectComponent: FC<InputProps<SelectField>> = ({field, input, meta}) => {
  const {isOpen, onClose, onOpen} = useDisclosure()
  const {placeholder, label, options, renderOption} = field
  const {onChange, value} = input
  const selected = useMemo(() => value && options.find(o => o.id === value), [value, options])
  return (
    <Flex flexFlow='column' align='center' w='100%'>
      {label ? <Text>{label}</Text> : null}
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton 
          _hover={{background: 'whiteAlpha.400'}} 
          // height={14} 
          w='100%' 
          // style={{padding: '0'}}
          onClick={e => {
            e.stopPropagation()
            onOpen()
          }} 
          variant='unstyled'
          as={Button} 
          >
          {selected ? renderOption(selected) : placeholder}
        </MenuButton>
        <MenuList>
          {options.map(o => (<MenuItem key={o.id} onClick={() => onChange(o.id)}>
          {renderOption(o)}
          </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}