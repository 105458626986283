import { AsyncFunction } from "./utils"

export enum SessionStatus {
  IN_QUEUE = 0,
  IN_PROGRESS = 1,
  COMPLETE = 2,
}

export interface Session {
  player: string
  challenger: string
  status: SessionStatus
  paymentId: string
  format: SessionFormat
}

export interface SessionFormat {
  name: string
  rate: number
}

// Session Setup
export interface CreateSessionArgs {
  player: string
  challenger: string

}

export interface AddPlayerArgs {
  playerId: string
  gameId: string
}

export interface StartSessionArgs {
  sessionId: string
}

export type CreateSession = AsyncFunction<CreateSessionArgs, string>
export type StartSession = AsyncFunction<StartSessionArgs, void>
