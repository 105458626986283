import { TimeField } from '@peaceful-creations/shared'
import { FC } from 'react'
import TimePicker from 'react-time-picker'
import { InputProps } from '../types'

export const toAmPm = (val: string) => {
  const hourIdx = val.indexOf(':')
  if (hourIdx === -1) return val
  const hour = parseInt(val.slice(0, hourIdx))
  const min = val.slice(hourIdx + 1)
  if (hour > 11) return `${hour - 12}:${min} PM`
  return `${hour}:${min} AM`
}

export const TimeInput: FC<InputProps<TimeField>> = ({
  field,
  input,
  meta,
}) => {
  return (
    <TimePicker
      disableClock
      {...input}
      value={input.value || ''}
    />
  )
}
