export const aboutBlurbs: Array<{
  heading: string
  body: string
  videoId?: string
}> = [
  {
    heading: 'What is Peaceful Creations?',
    body: `Peaceful Creations is a groovy 1974 VW Ice cream Dessert Bus serving tasty sundae concoctions inspired by icons who famously supported world peace. 

The sundae Peaceful Creations are named after: The Dalai Lama, Mahatma Gandhi, John Lennon, Malala Yousafzai, Mother Teresa, Nelson Mandela, and Martin Luther King Jr. 
  
These influential peaceful activists are featured on my menu with a quote of their words of wisdom. My intention is for people, especially children of the new generation, to see my menu and be reminded of the goodness in humanity and to feel at peace. 
  
For me, feeling at peace is an internal knowing that the world is not something to fear, that all humans are basically good, that we are one people, one family that is not separated by religion, ethnicity, gender, or race. Peaceful Creation’s mission is to inspire people to practice more love, peace, and happiness with one another all while enjoying a delicious ice cream treat on a hot summer day in Colorado.`,
  },
  {
    heading: 'About the Product',
    body: `Peaceful Creations ice cream product is homemade and locally sourced from Magill’s Ice Cream. They have been around since 1981, and use all-natural Colorado Dairy from local dairy Royal Crest along with the finest ingredients for their delicious homemade ice cream. 

Our menu also offers another option for most dietary restrictions. We serve locally sourced hemp ice cream sandwiches for people who are vegan, gluten-free, nut-free, and lactose-free.`,
  },
  {
    heading: 'Spreading Peace for the Community and the Planet',
    body: `Peaceful Creations serves free ice cream to our homeless community and at the end of every summer season, ice cream is donated to a non profit organization that supports and feeds the local homeless population. 

Past organizations that have been donated ice cream to are the Denver Rescue Mission, Feeding the Future, and the Arvada Food Bank. 

Peaceful Creations also promotes peace for our earth by committing to the zero waste pledge; which includes that all serving dishes, utensils, and napkins are certified compostable to be a more sustainable company for our Earth.`,
  },
]
