import { Field, FieldMap, TextField } from '@peaceful-creations/shared'

const fname: TextField = {
  _type: 'text',
  placeholder: 'First Name',
}

const lname: TextField = {
  _type: 'text',
  placeholder: 'Last Name',
}

const phone: Field = {
  _type: 'text',
  type: 'phone',
  placeholder: 'Phone Number',
}

const email: Field = {
  _type: 'text',
  type: 'email',
  placeholder: 'Email Address',
}

const eventAddress: Field = {
  _type: 'textarea',
  placeholder: 'Address of Event',
  optional: true,
}

const occasion: Field = {
  _type: 'text',
  placeholder: 'Occasion',
}

const date: Field = {
  _type: 'date',
  placeholder: 'Date',
}

const timeStart: Field = {
  _type: 'time',
  placeholder: 'Start Time',
}

const timeEnd: Field = {
  _type: 'time',
  placeholder: 'End Time',
}

const numPeople: Field = {
  _type: 'number',
  placeholder: 'Number of People Expected',
}

const budget: Field = {
  _type: 'currency',
  placeholder: 'Budget',
}

const notes: Field = {
  _type: 'textarea',
  placeholder: 'Notes...',
  optional: true,
}

export const bookingFields: FieldMap = {
  children: {
    fname,
    lname,
    phone,
    email,
    eventAddress,
    occasion,
    date,
    timeStart,
    timeEnd,
    numPeople,
    budget,
    notes,
  },
}
