import { Center, Image, Spinner, Text } from '@chakra-ui/react'
import { FileField, FileValue } from '@peaceful-creations/shared'
import { ChangeEventHandler, FC, useMemo, useRef, useState } from 'react'
import pdfIcon from './icons/pdf.svg'
import { InputProps } from './types'

const generateAccept = (field: FileField) => {
  if (!field.accept) return undefined
  const accept = []
  if (field.accept.includes('image'))
    accept.push(
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/svg',
      'image/gif'
    )
  if (field.accept.includes('pdf')) accept.push('application/pdf')
  return accept.join(',')
}

const reader = new FileReader()

export const FileView = ({ value }: { value?: FileValue }) => {
  const { url, dataUrl } = value || {}
  const [loaded, setLoaded] = useState(false)
  const isImage = useMemo(() => value?.type?.indexOf('image') === 0, [value])
  if (!value)
    return (
      <Text opacity={0.8}>
        <i>Upload an image</i>
      </Text>
    )
  return <Center w='100%' height='100%'>
    {
isImage ? <Image 
width='100%'
height='100%'
opacity={loaded ? 1 : 0}
transition='opacity 333ms ease'
objectFit='contain'
    onLoad={() => setLoaded(true)} 
    src={dataUrl || url} 
    /> : <Image src={pdfIcon} />
    }
    {isImage && !loaded ? <Spinner position='absolute' color='white'/> : null}
  </Center> 
}
export const FileComponent: FC<InputProps<FileField>> = ({
  input: { value, onChange, onBlur, onFocus },
  field,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0]
    if (!file) return
    reader.addEventListener(
      'load',
      async () => {
        // convert image file to base64 string
        if (typeof reader.result !== 'string') {
          console.error('reader result isnt a data url')
          return
        }
        onChange({ ...value, dataUrl: reader.result, type: file.type })
      },
      false
    )
    reader.readAsDataURL(file)
  }
  return (
    <Center
      height={200}
      cursor='pointer'
      onClick={() => inputRef.current?.click()}
      width='100%'
    >
      <Center 
      maxW='200px' 
      width='100%' 
      height='100%'
      borderRadius='full'
      overflow='hidden'
      background='whiteAlpha.500'
      >
      <FileView value={value}/>
      <input
        onChange={handleChange}
        ref={inputRef}
        accept={generateAccept(field)}
        type='file'
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          height: 0.1,
          width: 0.1,
          opacity: 0,
        }}
      />
      </Center>

    </Center>
  )
}
