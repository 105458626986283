import { TextField } from '@peaceful-creations/shared'

function formatPhoneNumber(phoneNumberString?: string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  if (cleaned.length > 10) {
    let countryCodeLen = Math.min(cleaned.length - 10, 2)
    return `+ ${cleaned.substring(0, countryCodeLen)} (${cleaned.substring(
      countryCodeLen,
      countryCodeLen + 3
    )}) ${cleaned.substring(
      countryCodeLen + 3,
      countryCodeLen + 6
    )} ${cleaned.substring(
      countryCodeLen + 6,
      Math.min(countryCodeLen + 10, 12)
    )}`
  }
  let ret = ''
  if (cleaned.length) {
    ret += '(' + cleaned.substring(0, 3)
  }

  if (cleaned.length > 3) ret += ') ' + cleaned.substring(3, 6)
  if (cleaned.length > 6) ret += ' ' + cleaned.substring(6)
  return ret
}

const parsePhoneNumber = (val: string) => val.replace(/[^0-9\.]+/g, '')

export const formatField: Partial<
  Record<Required<TextField>['type'], (v: any) => string>
> = {
  phone: formatPhoneNumber,
}

export const parseField: Partial<
  Record<Required<TextField>['type'], (v: string) => any>
> = {
  phone: parsePhoneNumber,
}
