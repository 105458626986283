import { useProgress } from '@react-three/drei'
import { useEffect, useMemo, useState } from 'react'

export const useApp = () => {
  const { loaded, total } = useProgress()
  const isLoaded = useMemo(() => loaded > 9, [loaded])
  const [override, setOverride] = useState(false)

  useEffect(() => {
    const t = setTimeout(() => {
      setOverride(true)
    }, 3000)
    return () => {
      clearTimeout(t)
    }
  }, [])
  return { loaded: isLoaded, override }
}
