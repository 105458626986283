import { TextAreaField } from '@peaceful-creations/shared'
import { FC } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { InputProps } from './types'

export const TextAreaComponent: FC<InputProps<TextAreaField>> = ({
  input,
  field,
}) => {
  return (
    <TextareaAutosize
      placeholder={field.placeholder}
      minRows={2}
      maxRows={5}
      style={{
        background: 'none',
        // color: 'white',
        // height: 'auto',
        resize: 'none',
        width: '100%',
        padding: '0.5rem 1rem',
        boxSizing: 'border-box',
        borderRadius: '4px',
        border: 'none',
        outline: 'none',
      }}
      {...input}
    />
  )
}
