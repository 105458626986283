import { Preload, useTexture } from '@react-three/drei'
import { Suspense } from 'react'
import { RepeatWrapping, Texture } from 'three'

const processTexture = (t: Texture | Texture[]) => {
  const tex = t as Texture
  tex.wrapS = RepeatWrapping
  tex.wrapT = RepeatWrapping
  tex.repeat.set(50, 50)
  tex.needsUpdate = true
}
const GroundMaterial = () => {
  const map = useTexture('/assets/3d/ground/dirt-color.jpg', processTexture)
  const normalMap = useTexture(
    '/assets/3d/ground/dirt-normal.jpg',
    processTexture
  )
  const bumpMap = useTexture('/assets/3d/ground/dirt-bump.jpg', processTexture)

  return (
    <meshPhongMaterial
      map={map}
      shininess={0.001}
      normalMap={normalMap}
      bumpMap={bumpMap}
      bumpScale={20}
    />
  )
}

export const Ground = () => {
  return (
    <mesh position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]}>
      <circleBufferGeometry args={[150, 64]} />
      <Suspense
        fallback={<meshPhongMaterial color={0x594431} shininess={0.001} />}
      >
        <Preload all />
        <GroundMaterial />
      </Suspense>
    </mesh>
  )
}
useTexture.preload('/assets/3d/ground/dirt-color.jpg')
useTexture.preload('/assets/3d/ground/dirt-normal.jpg')
useTexture.preload('/assets/3d/ground/dirt-bump.jpg')
