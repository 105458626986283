import { useFrame, useThree } from '@react-three/fiber'
import { useEffect } from 'react'

export const CameraController = () => {
  const camera = useThree((s) => s.camera)
  useFrame(({ clock }) => {
    const theta =
      (Math.PI / 1.2) * Math.sin(clock.elapsedTime / 120) + Math.PI / 8
    const x = 15 * Math.cos(theta)
    const z = 15 * Math.sin(theta)
    camera.position.set(x, 3, z)
    camera.lookAt(0, 5, 0)
  })
  return null
}
