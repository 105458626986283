import { Preload, useGLTF } from '@react-three/drei'
import { Suspense } from 'react'

const BusBase = () => {
  const { scene } = useGLTF('/assets/3d/bus-new.glb')

  return (
    <primitive
      scale={[8, 8, 8]}
      rotation={[0, Math.PI / 2.2, 0]}
      object={scene}
    />
  )
}

export const Bus = () => (
  <Suspense>
    <Preload all/>
    <BusBase />
  </Suspense>
)
useGLTF.preload('/assets/3d/bus-new.glb')
