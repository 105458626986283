import { FileValue } from './files'

type BaseField = {
  optional?: boolean
}
export type TextField = BaseField & {
  _type: 'text'
  type?: 'password' | 'email' | 'text' | 'phone'
  value?: string
  placeholder: string
  label?: JSX.Element | string
  validate?: (value?: string) => string | undefined
}

export type TextAreaField = BaseField & {
  _type: 'textarea'
  value?: string
  placeholder: string
  label?: JSX.Element
  validate?: (value?: string) => string | undefined
}

export type DateField = BaseField & {
  _type: 'date'
  value?: number
  placeholder: string
  label?: JSX.Element | string
  validate?: (value?: number) => string | undefined
}

export type TimeField = BaseField & {
  _type: 'time'
  value?: string
  placeholder: string
  label?: JSX.Element | string
  validate?: (value?: string) => string | undefined
}

export type SelectField<T = any> = BaseField & {
  _type: 'select'
  options: Array<T>
  label?: string
  renderOption: (opt: T) => React.ReactNode
  placeholder: string
  value?: string
  validate?: (value?: string) => string | undefined
}

export type BooleanField = BaseField & {
  _type: 'boolean'
  type?: 'switch' | 'checkbox'
  placeholder: string
  label?: string
  value?: boolean
  validate?: (value?: boolean) => string | undefined
}

export type FileField = BaseField & {
  _type: 'file'
  value?: FileValue
  getPath: (user: any) => string
  label: string
  placeholder?: string
  accept: Array<'image' | 'pdf'>
  validate?: (value?: FileValue) => string | undefined
}

export type NumberField = BaseField & {
  _type: 'number'
  value?: string
  label?: string
  placeholder?: string
  validate?: (value?: string) => string | undefined
}

export type CurrencyAmountField = BaseField & {
  _type: 'currency'
  value?: string
  label?: string
  placeholder?: string
  validate?: (value?: string) => string | undefined
}

export type RecordField = BaseField & {
  itemField: Field | FieldMap
  value?: any
  label?: JSX.Element
  validate?: (value?: Array<any>) => string | undefined
}

type AnyObject = { [key: string]: any }
export type Field =
  | TextField
  | FileField
  | BooleanField
  | SelectField
  | TextAreaField
  | CurrencyAmountField
  | NumberField
  | DateField
  | TimeField
export type FieldType = Field['_type']
export type FieldMap = {
  name?: string
  validate?: (v: any) => AnyObject | undefined
  children: { [key: string]: IFormElement }
}

export type IFormElement = Field | FieldMap | RecordField
export const isField = (
  fieldOrFieldMap: IFormElement
): fieldOrFieldMap is Field => !!(fieldOrFieldMap as Field)._type
export const isRecordField = (
  fieldOrFieldMap: IFormElement
): fieldOrFieldMap is RecordField =>
  !!(fieldOrFieldMap as RecordField).itemField
