import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AppContext } from '../../context'
import { useApp } from '../../hooks/useApp'
import { useScreen } from '../../hooks/useScreen'
import { Home } from './pages/Home/Home'
import { theme } from './theme'
import './App.css'
import { Scene } from './Scene/Scene'
import { LoadingScreen } from './LoadingScreen'

const App = () => {
  const app = useApp()
  const { loaded, override } = app
  const { ref, screen } = useScreen()
  return (
    <AppContext.Provider value={{ ...app, screen }}>
      <div
        ref={ref}
        className='App'
        style={{
          transition: 'all 1000ms ease',
          overflowY: 'auto',
          opacity: loaded || override ? 1 : 0,
          width: '100%',
          height: '100%',
          background: `radial-gradient(circle, rgba(140,97,196,${
            loaded ? 0 : 1
          }) 0%, rgba(46,119,186,${loaded ? 0 : 1}) 60%, rgba(109,214,255,${
            loaded ? 0 : 1
          }) 120%)`,
        }}
      >
        <Scene />
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
      </div>
      <LoadingScreen />
    </AppContext.Provider>
  )
}

const Router = () => (
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </BrowserRouter>
)

export default Router
