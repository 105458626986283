import { DateField } from '@peaceful-creations/shared'
import { FC, useMemo } from 'react'
import { InputProps } from '../types'
import DatePicker from 'react-date-picker'

export const DateInput: FC<InputProps<DateField>> = ({
  field,
  input,
  meta,
}) => {
  const value = useMemo(
    () => (input.value ? new Date(input.value) : undefined),
    [input.value]
  )
  const onChange = (e: Date) => {
    input.onChange(e.getTime())
  }
  return <DatePicker disableCalendar value={value} onChange={onChange} />
}
