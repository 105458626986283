import { Image } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context'
import whiteLogo from './icons/logo8.svg'

export const LoadingScreen = () => {
  const { loaded, override } = useContext(AppContext)

  return (
    <div
      style={{
        transition: 'opacity 1000ms ease',
        opacity: override || loaded ? 0 : 1,
        display: 'flex',
        justifyContent: 'center',
        background:
          'radial-gradient(circle, rgba(140,97,196,1) 0%, rgba(46,119,186,1) 49%, rgba(109,214,255,1) 100%)',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        filter: 'drop-shadow(0 0 4px black)',
      }}
    >
      <Image
        style={{ height: '340px' }}
        className='loading-logo'
        src={whiteLogo}
        alt='loading-logo'
      />
    </div>
  )
}
