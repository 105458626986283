import { Collapse, CollapseProps, useTimeout } from "@chakra-ui/react";
import { FC, useState } from "react";


export const ExpandOnMount:FC<CollapseProps> = (props) => {
  const[mounted, set] = useState(false)
  useTimeout(() => set(true), 133)
  return(
    <Collapse {...props} in={mounted}/>
  )

}