import { CurrencyAmountField, NumberField } from '@peaceful-creations/shared'
import { FC } from 'react'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import { InputProps } from '../types'

export const NumberComponent: FC<
  InputProps<NumberField> & CurrencyInputProps
> = ({ input: { onChange, ...input }, field, meta, ...props }) => {
  return (
    <CurrencyInput
      style={{
        background: 'none',
        // color: 'white',
        // height: 'auto',
        resize: 'none',
        width: '100%',
        padding: '0.5rem 1rem',
        boxSizing: 'border-box',
        borderRadius: '4px',
        border: 'none',
        outline: 'none',
      }}
      placeholder={field.placeholder}
      onValueChange={(v) => {
        onChange(v)
      }}
      {...props}
      {...input}
    />
  )
}

export const CurrencyAmountComponent: FC<InputProps<CurrencyAmountField>> = ({
  field,
  ...props
}) => (
  <NumberComponent
    prefix='$'
    field={{ ...field, _type: 'number' }}
    {...props}
  />
)
