import { Button, ButtonProps, Collapse, Stack, Text } from '@chakra-ui/react'
import { FieldMap, isField, isRecordField } from '@peaceful-creations/shared'
import { AnyObject, FormApi, FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { FC, useMemo } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'
import { FormElement } from './input'
import { FieldFormProps, FieldMapFormProps, FormProps } from './input/types'
import { validateFieldMap } from './input/utils'

export const SubmitButton: FC<
  Pick<FormRenderProps, 'handleSubmit' | 'submitting'> & ButtonProps
> = ({ handleSubmit, submitting, children, filter, opacity }) => (
  <Button
    isLoading={submitting}
    filter={filter}
    transition='all 500ms'
    opacity={opacity}
    color='#E46E00'
    onClick={handleSubmit}
    ml='auto'
  >
    {children}
  </Button>
)

export const FieldMapForm: FC<FieldMapFormProps> = ({
  onSubmit,
  field,
  value,
  buttonText = 'SUBMIT',
  renderFooter,
}) => {
  const handleSubmit = async (data: AnyObject, form: FormApi) => {
    try {
      const res = await onSubmit(data, form)
      return res
    } catch (err) {
      console.log(err)
      return { [FORM_ERROR]: (err as any).message || 'An error occurred' }
    }
  }
  return (
    <Stack w='100%' spacing={3} py={2} px={2}>
      <FinalForm
        mutators={{ ...arrayMutators }}
        initialValues={value}
        validate={(v) => validateFieldMap(field, v)}
        onSubmit={handleSubmit}
        subscription={{
          values: true,
          submitting: true,
          errors: true,
          valid: true,
        }}
        render={({ handleSubmit, submitting, values, errors, valid }) => (
          <>
            <form onSubmit={handleSubmit}>
              <FormElement field={field} />
            </form>
            <Collapse in={!!errors?.[FORM_ERROR]}>
              <Text color='red'>{errors?.[FORM_ERROR] || ''}</Text>
            </Collapse>
            {renderFooter ? (
              renderFooter({ handleSubmit, submitting, values })
            ) : (
              <SubmitButton
                size='sm'
                filter={`grayscale(${!valid ? 100 : 0}%)`}
                opacity={!valid ? 0.5 : 1}
                handleSubmit={handleSubmit}
                submitting={submitting}
              >
                {buttonText}
              </SubmitButton>
            )}
          </>
        )}
      />
    </Stack>
  )
}

export const FieldForm: FC<FieldFormProps> = ({
  onSubmit,
  field,
  value,
  ...props
}) => {
  const fieldMap = useMemo<FieldMap>(
    () => ({ children: { value: field } }),
    [field]
  )
  return (
    <FieldMapForm
      field={fieldMap}
      value={{ value }}
      onSubmit={(data, form) => onSubmit(data.value, form)}
      {...props}
    />
  )
}

export const Form: FC<FormProps> = ({ field, ...props }) => {
  if (isField(field) || isRecordField(field))
    return <FieldForm field={field} {...props} />
  return <FieldMapForm field={field} {...props} />
}
