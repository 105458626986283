import { Sky, Stars } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react'
import {
  DirectionalLight,
  PointLight,
  Points,
  ShaderMaterial,
  Vector3Tuple,
} from 'three'
import { Sky as SkyI } from 'three-stdlib'
import { Ground } from './Ground'

const TIME_IN_DAY = (1000 * 60 * 60 * 24) / 1000
export const Environment = () => {
  const stars = useRef<Points>(null)
  const sky = useRef<SkyI>(null)
  const skyMat = useRef<ShaderMaterial | null>(null)
  const [sunLight] = useState(new DirectionalLight(0xffeedd, 0.5))
  const [moonLight] = useState(new DirectionalLight(0xffffff, 0.5))
  const [nightLight] = useState(new PointLight(0xffcc77, 0.5))
  const [nightLight2] = useState(new PointLight(0xffcc77, 0.5))
  useEffect(() => {
    if (!sky.current) return
    skyMat.current = sky.current.material as ShaderMaterial
    sunLight.target.position.set(0, 0, 0)
  }, [])

  useFrame(({ clock }) => {
    if (!stars.current) return
    if (!skyMat.current) return
    if (!sky.current) return
    // const t = (startTime + clock.elapsedTime) % TIME_IN_DAY
    const t = clock.elapsedTime
    const sunFactor = (0.5 * Math.sin(t / 5) + 0.5) ** 3
    const scale = 10 * sunFactor
    const sunPos: Vector3Tuple = [
      100 * Math.cos(t / 5),
      50 * sunFactor - 10,
      100 * Math.cos(t / 18),
    ]
    skyMat.current.uniforms.sunPosition.value.set(...sunPos)
    sky.current.rotation.set(
      -Math.PI * 2 * Math.cos(t / 30),
      Math.PI * 2 * Math.cos(t / 50),
      Math.PI * 2 * Math.sin(t / 70)
    )
    sunLight.position.set(...sunPos)
    sunLight.intensity = sunFactor * 1.5
    moonLight.position.copy(sunLight.position).multiplyScalar(-1)
    moonLight.intensity = (1 - sunFactor) / 3
    nightLight.intensity = moonLight.intensity
    // nightLight.position.set(4, 10 + sunFactor * 20, -6)
    nightLight2.intensity = moonLight.intensity
    // nightLight2.position.set(-6, 10 + sunFactor * 20, 10)
    stars.current.scale.set(1 + scale, 1 + scale, 1 + scale)
  })
  return (
    <>
      <primitive object={sunLight} />
      <primitive object={moonLight} />
      <primitive position={[4, 10, -6]} object={nightLight} />
      <primitive position={[-6, 10, 10]} object={nightLight2} />
      <ambientLight intensity={0.1} color={0xffffff} />
      <Ground />
      <Stars ref={stars} />
      <Sky
        mieCoefficient={0.002}
        mieDirectionalG={0.988}
        rayleigh={0.4}
        turbidity={8.2}
        ref={sky}
      />
    </>
  )
}
