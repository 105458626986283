import { Preload, useGLTF } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { Bus } from './Bus'
import { CameraController } from './CameraController'
import { Environment } from './Environment'
import { ThingDisperser } from './ThingDisperser'

export const Scene = () => {
  return (
    <Canvas
      camera={{ position: [0, 5, -25] }}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: -1,
      }}
    >
      <CameraController />
      <Environment />
      <Bus />
      <ThingDisperser
        transformsPerMesh={40}
        radius={100}
        excludeFirst={10}
        path='/assets/3d/trees-reduced.glb'
      />
      <ThingDisperser
        transformsPerMesh={20}
        radius={60}
        excludeFirst={5}
        path='/assets/3d/rocksandfoliage-reduced.glb'
      />
    </Canvas>
  )
}

useGLTF.preload('/assets/3d/trees-reduced.glb')
useGLTF.preload('/assets/3d/rocksandfoliage-reduced.glb')
