import useResizeObserver from "@react-hook/resize-observer"
import { useLayoutEffect, useRef, useState } from "react"

export const  useScreen = () => {
  const [screen, set] = useState<DOMRectReadOnly | null>(null)
  const ref = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    if (!ref.current) return
    set(ref.current.getBoundingClientRect())
  }, [ref])
  useResizeObserver(ref, (entry) => set(entry.contentRect))
  return { ref, screen }
}