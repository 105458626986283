import { Button, Center, Flex, Heading, Image, VStack } from '@chakra-ui/react'
import { useContext } from 'react'
import { AppContext } from '../../../../context'
import logo from '../../icons/logo8.svg'
import { About } from './About'
import { Booking } from './Booking'
import { Calendar } from './Calendar'
import { Gallery } from './Gallery'
import { Menu } from './Menu'

const Logo = () => (
  <Image
    mx={[0, 0, 8]}
    src={logo}
    height={['80px', '100px', '140px']}
    alt='logo'
  />
)
const Peaceful = () => (
  <Heading
    fontFamily='DynaPuff'
    color='#ffffff'
    textAlign='center'
    px={4}
    fontSize={['2rem', '2rem', '3rem']}
    // textShadow='0 0 8px black'
  >
    PEACEFUL
  </Heading>
)

const Creations = () => (
  <Heading
    fontFamily='DynaPuff'
    color='#ffffff'
    textAlign='center'
    px={4}
    fontSize={['2rem', '2rem', '3rem']}
    // textShadow='0 0 8px black'
  >
    CREATIONS
  </Heading>
)

const Welcome = () => {
  const { screen } = useContext(AppContext)

  const { width = 300 } = screen || {}

  return width > 600 ? (
    <Center>
      <Peaceful />
      <Logo />
      <Creations />
    </Center>
  ) : (
    <Flex alignItems='center'>
      <Logo />
      <VStack spacing={0} align='flex-start'>
        <Peaceful />
        <Creations />
      </VStack>
    </Flex>
  )
}

export const Home: React.FC = () => {
  return (
    <VStack zIndex={2} h='100%' w='100%'>
      <Center
        filter='drop-shadow(0 0 4px black)'
        flexFlow='column'
        w='100%'
        justifyContent='space-between'
        h='100%'
        overflow='hidden'
        py={6}
      >
        <Welcome />
        <Flex alignItems='center' direction='column'>
          <Flex overflow='hidden' align='center'>
            <About />
            <Calendar />
            <Booking />
          </Flex>
          {/* <Flex overflow='hidden' align='center'>
            <Menu/>
            <Gallery />
          </Flex> */}
        </Flex>
      </Center>
    </VStack>
  )
}
