import { CalendarIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import locationIcon from '../../icons/location.svg'

const CALENDAR_ID = 'kaylavoeltzel420@gmail.com'
const API_KEY = 'AIzaSyDfgebJSP_usFxZWDAif7dRspKTehS3zjk'

type EventDate = { dateTime?: string; date?: string }
type Event = {
  start?: EventDate
  end?: EventDate
  summary?: string
  description?: string
  location?: string
  visibility: string
}

const EventLocation: FC<{ event: Event }> = ({ event }) => {
  const text = useMemo(() => {
    if (!event.location) return ''
    return event.location
    // return event.location.length > 40
    //   ? `${event.location.substring(0, 37)}...`
    //   : event.location
  }, [event])
  return event.location ? (
    <a
      target='_blank'
      style={{ width: '100%' }}
      href={`https://www.google.com/maps/search/${encodeURI(event.location)}`}
    >
      <Flex px={3} w='100%' justify='center' alignItems='center'>
        <Image src={locationIcon} mr={2} height='14px' />
        <Text
          // px={4}
          textOverflow='ellipsis'
          overflow='hidden'
          whiteSpace='nowrap'
          opacity={0.7}
          fontWeight={600}
          fontSize='0.9rem'
        >
          {text.toUpperCase()}
        </Text>
      </Flex>
    </a>
  ) : null
}

const toDateString = (date?: EventDate, noDate?: boolean) => date?.dateTime
  ? new Date(date.dateTime).toLocaleString(undefined, {
    dateStyle: noDate ? undefined : 'short',
    timeStyle: 'short',
  })
  : (date?.date)
    ? new Date(date?.date).toLocaleDateString(undefined, {
      dateStyle: 'short',
    })
    : null


const EventTime: FC<{ event: Event }> = ({ event }) => {
  const text = useMemo(() => {
    let startString = toDateString(event.start)
    let endString = toDateString(event.end, true)
    return startString ? `${startString}${endString ? ` - ${endString}` : ''}` : 'TBA'
  }, [event])
  return (
    <Flex alignItems='center'>
      <CalendarIcon color={'#1C75BC'} mr={2} fontSize='sm' />
      <Text opacity={0.7} fontWeight={600} fontSize='0.9rem'>
        {text.toUpperCase()}
      </Text>
    </Flex>
  )
}

const EventView: FC<{ event: Event }> = ({ event }) => {
  return (
    <VStack
      spacing={0}
      w='100%'
      background='white'
      borderRadius={6}
      px={3}
      py={4}
    >
      <Text pb={2} color='blackAlpha.700' fontSize='1.2rem'>
        {event.summary?.toUpperCase() || ''}
      </Text>
      <EventTime event={event} />
      <EventLocation event={event} />
    </VStack>
  )
}

export const Calendar = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [events, setEvents] = useState<Array<Event>>([])
  const [hasLoaded, setHasLoaded] = useState(false)
  const [hasFetched, setHasFetched] = useState(false)
  useEffect(() => {
    if (!isOpen || hasFetched) return
    setHasFetched(true)
    fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}&timeMin=${new Date().toISOString()}`
    ).then((res) => {
      res.json().then((data) => {
        setEvents(
          (data.items as Event[])
            .filter((i) => i.visibility !== 'private')
            .sort((a, b) =>
              new Date(a.start?.dateTime || a.start?.date || 0) < new Date(b.start?.dateTime || b?.start?.date || 0) ? -1 : 1
            )
        )
        setHasLoaded(true)
      })
    })
  }, [isOpen, hasFetched])
  return (
    <>
      <Modal
        scrollBehavior='inside'
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent maxH='450px' background='transparent' shadow='none'>
          <ModalBody
            overflow='hidden'
            display='flex'
            flexFlow='column'
            px={4}
            h='100%'
            textAlign='center'
            background='transparent'
          >
            <Heading
              color='white'
              textShadow='0 0 4px black'
              textAlign='center'
              fontSize='1.8rem'
              pb={2}
            >
              Upcoming Events
            </Heading>
            <VStack
              py={4}
              px={2}
              sx={{
                WebkitMaskImage:
                  'linear-gradient(transparent, black 5%, black 95%, transparent 100%)',
                maskImage:
                  'linear-gradient(transparent, black 5%, black 95%, transparent 100%)',
              }}
              flex={1}
              overflow='auto'
              spacing={4}
            >
              {events.map((e, i) => (
                <EventView key={`event-${i}`} event={e} />
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Button onClick={onOpen}>EVENTS</Button>
    </>
  )
}
