import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Input } from '@chakra-ui/react'
import { TextField } from '@peaceful-creations/shared'
import { useMemo, useState } from 'react'
import { InputProps } from '../types'
import { formatField, parseField } from './utils'

export const TextComponent = ({
  input,
  field: { placeholder, type = 'text', optional },
  meta,
}: InputProps<TextField>) => {
  const [isHidden, setIsHidden] = useState(type === 'password')

  const parse = useMemo(() => parseField[type] || ((v?: string) => v), [type])
  const format = useMemo(() => formatField[type] || ((v?: string) => v), [type])
  const { value } = input
  const body = (
    <Input
      border='none'
      borderRadius={0}
      type={isHidden ? 'password' : 'text'}
      background='none'
      _focus={{ border: 'none' }}
      placeholder={optional ? placeholder : `${placeholder} *`}
      {...input}
      onChange={(v) => {
        input.onChange({ target: { value: parse(v.target.value) } })
      }}
      value={format(value as string)}
    />
  )

  return type === 'password' ? (
    <Flex width='100%' position='relative' align='center'>
      {body}
      <IconButton
        position='absolute'
        right={2}
        onClick={() => setIsHidden(!isHidden)}
        variant='ghost'
        icon={isHidden ? <ViewIcon /> : <ViewOffIcon />}
        aria-label={isHidden ? 'show' : 'hide'}
        title={isHidden ? 'Show' : 'Hide'}
        size='xs'
      />
    </Flex>
  ) : (
    body
  )
}
