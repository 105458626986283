import { Vector2Tuple } from 'three'

const { sqrt, sin, cos, round, PI } = Math
const PHI = (1 + sqrt(5)) / 2 // golden ratio

const radius = (k: number, n: number, b: number) => {
  if (k > n - b) return 1.0

  return sqrt(k - 0.5) / sqrt(n - (b + 1) / 2)
}

export const sunflower = (n: number, rad = 1, alpha = 0, geodesic = false) => {
  const points: Array<Vector2Tuple> = []
  const angleStride = geodesic ? 360 * PHI : (2 * PI) / PHI ** 2
  const b = round(alpha * sqrt(n)) // number of boundary points
  for (let k = 0; k <= n; k += 1) {
    const r = rad * radius(k, n, b)
    const theta = k * angleStride
    points.push([r * cos(theta), r * sin(theta)])
  }

  return points
}
