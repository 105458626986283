// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCTMhrVxcWsnVMYAJ69T4l4Vr-klnVd7M8',
  authDomain: 'peaceful-creations.firebaseapp.com',
  projectId: 'peaceful-creations',
  storageBucket: 'peaceful-creations.appspot.com',
  messagingSenderId: '20480703597',
  appId: '1:20480703597:web:e4df21e965d796df585364',
  measurementId: 'G-QHHKRHHP2P',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
