import { Instance, Instances, Preload, useGLTF } from '@react-three/drei'
import { FC, Suspense, useEffect, useMemo } from 'react'
import { Mesh, MeshStandardMaterial, Vector3Tuple } from 'three'
import { sunflower } from './utils'

type MyGltf = {
  nodes: Record<string, Mesh>
}
const instanceMapper = [1, 1, 1, 1, 1]

const TRANSFORMS_PER_MESH = 5

type ThingDisperserProps = {
  transformsPerMesh?: number
  radius?: number
  excludeFirst?: number
  path: string
}

const ThingDisperserBase: FC<ThingDisperserProps> = ({
  path,
  transformsPerMesh = 5,
  radius = 30,
  excludeFirst = 0,
}) => {
  const { nodes } = useGLTF(path) as unknown as MyGltf
  const { meshes, transforms } = useMemo(() => {
    const meshes = Object.values(nodes).filter((n) => n.isMesh)
    const numTransforms = transformsPerMesh * meshes.length
    const transforms = sunflower(numTransforms + excludeFirst, radius)
      .slice(excludeFirst)
      .sort(() => (Math.random() > 0.5 ? -1 : 1))
      .map(([x, z]) => ({
        position: [x, 0, z] as Vector3Tuple,
        rotation: [0, 2 * Math.PI * Math.random(), 0] as Vector3Tuple,
      }))
    return { meshes, transforms }
  }, [nodes, transformsPerMesh, radius, excludeFirst])
  useEffect(() => {
    meshes.forEach((m) => {
      const mat = m.material as MeshStandardMaterial
      mat.roughness = 0.66
    })
  }, [meshes])

  return (
    <>
      {meshes.map(({ geometry, material, name }, i) => (
        <Instances key={name} geometry={geometry} material={material}>
          {transforms
            .slice(i * transformsPerMesh, (i + 1) * transformsPerMesh)
            .map((transform, i) => (
              <Instance key={`instance-${i}`} {...transform} />
            ))}
        </Instances>
      ))}
    </>
  )
}

export const ThingDisperser: FC<ThingDisperserProps> = (props) => {
  return (
    <Suspense fallback={null}>

      <Preload all/>
      <ThingDisperserBase {...props} />
    </Suspense>
  )
}
